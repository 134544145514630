import { Order } from '@/features/orders';
import { $t } from '@/i18n';

export type ZplTemplateStaticTranslations = {
  curbside: string;
};

export type zplOrderDto = Pick<
  Order,
  'pickupCode' | 'customer' | 'orderReference' | 'startTime'
>;

export type PrintedLabel = {
  code?: string;
  bagNumber: number;
};

export const zebraPrintError = $t('errors.label-print.zebra-print.message');
